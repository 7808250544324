<template>
  <v-app>
    <v-container>
      <v-card>
        <v-card-title class="pb-0" style="justify-content: space-between">
          Дашборд
        </v-card-title>
        <v-row>
            <DatePeriod @change="reloadTable" v-model="date"/>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-select
                multiple
                outlined
                dense
                :items="[{text: 'Выбрать все', value: 'all'}, ...$store.state.toSelectItems($store.state.user.allCities, 'city', 'id')]"
                v-model="cities"
                @change="saveLocal($event)"
                no-data-text="Данных нет"
            ></v-select>
          </v-col>
        </v-row>
        <DashboardStats :date="date" :cities="cities"/>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>

import YearMonth from "../../components/YearMonth";
import Percent from "../../components/Percent";
import IconTip from "../../components/IconTip";
import DashboardStats from "../../components/DashboardStats";
import DatePeriod from "../../components/DatePeriod";
import OrdersSum from "../../components/OrdersSum";

export default {
  name: "Salary",
  components: {OrdersSum, DatePeriod, DashboardStats, IconTip, Percent, YearMonth},
  data() {
    let today = this.$moment().format('YYYY-MM-DD');
    let cities = this.$root.getData('regions_dashboard', 'cities') || ['all'].concat(this.$store.state.getObjectsField(this.$store.state.user.cities));
    return {
      rows: [],
      total: {},
      cities,
      citiesPrev: cities,
      date: [this.$moment().startOf('month').format('YYYY-MM-DD'), today],
      loadingTable: true,
      filter: {},
      headers: [
        {text: 'Регион', value: 'city'},
        {text: 'План', value: 'plan'},
        {text: 'Передано в город', value: 'receivedOrdersTotal'},
        {text: 'Отмененных из тех, что передали', value: 'receivedOrdersCancelled'},
        {text: 'Клинеров с заказом (сред)', value: 'cleanersInOrder.avg'},
        {text: 'Выручка на клинера с заказом', value: 'revenuePerCleanerInOrder'},
        {text: 'Выручка', value: 'revenue'},
        // {text: 'Баланс Facebook', value: 'facebook'},
        {text: 'Баланс Yandex', value: 'yandex'},
        {text: 'Висит в обзвоне', value: 'feedback'},
        {text: 'Средняя оценка', value: 'feedbackAvg'},
      ],
    }
  },
  methods: {
    percent(a, b, diff = false) {
      let res = parseInt(a / b * 100) || 0;
      if (res && diff) res = res - 100;
      let color = '';
      if (res < 0) {
        color = 'red';
      } else if (res > 0) {
        color = 'green';
        res = '+' + res;
      }
      return `<span style="color: ${color}">${res}%</span>`;
    },
    reloadTable() {
      this.rows = [];
      this.total = {};
      this.loadingTable = true;
      this.$store.state.server.request('regions/workStats', {date: this.date}, (data) => {
        this.rows = data.response;
        this.loadingTable = false;
      }, () => {
        this.loadingTable = false
      })
    },
    saveLocal() {
      this.$root.saveData('regions_dashboard', 'cities', this.cities)
      if (this.cities.includes('all') && !this.citiesPrev.includes('all')) this.cities = ['all'].concat(this.$store.state.getObjectsField(this.$store.state.user.allCities));
      else if (!this.cities.includes('all') && this.citiesPrev.includes('all')) this.cities = ['1'];
      this.citiesPrev = this.cities.slice();
    }
  },
  mounted() {
    this.reloadTable()
  }
}
</script>
